import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { createContentsContext } from "@punks/core"
import { mapSanityContents } from "@punks/contents-sanity"
import {
  LayoutsQuery,
  LayoutsQuery_allSanityLayout_nodes as LayoutItem,
} from "../../__generated__/LayoutsQuery"

export const LayoutContents = createContentsContext<LayoutItem>()

interface Props {
  children: any
}

export const LayoutContentsProvider = ({ children }: Props) => {
  const data = useStaticQuery<LayoutsQuery>(graphql`
    query LayoutsQuery {
      allSanityLayout {
        nodes {
          _id
          data {
            info {
              name
            }
            header {
              logo {
                asset {
                  url
                }
              }
            }
            footer {
              _rawCompanyInfo(resolveReferences: { maxDepth: 10 })
            }
            body {
              background {
                texture
                image {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <LayoutContents.Provider
      value={mapSanityContents(data.allSanityLayout.nodes, {
        alias: (x) => x.data?.info?.name ?? undefined,
      })}
    >
      {children}
    </LayoutContents.Provider>
  )
}
