import React from "react"
import DefineStaticContents from "./contents"
import { PunksProviders } from "./punks"

interface Params {
  element: any
}

export const wrapRootElement = ({ element }: Params) => {
  return (
    <PunksProviders>
      <DefineStaticContents>{element}</DefineStaticContents>
    </PunksProviders>
  )
}
