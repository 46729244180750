import React from "react"
import { SanityProviders } from "@punks/contents-sanity"
import { SiteTrackingProvider } from "./SiteTrackingProvider"
import { SiteThemeProvider } from "./SiteThemeProvider"

interface Props {
  children: any
}

const SanityPunksProviders = ({ children }: Props) => {
  return (
    <SanityProviders>
      <SiteTrackingProvider>
        <SiteThemeProvider>{children}</SiteThemeProvider>
      </SiteTrackingProvider>
    </SanityProviders>
  )
}

export default SanityPunksProviders
