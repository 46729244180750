import React from "react"
import { LayoutContentsProvider } from "./layout"

interface Props {
  children: any
}

const StaticContentsProvider = ({ children }: Props) => {
  return <LayoutContentsProvider>{children}</LayoutContentsProvider>
}

export default StaticContentsProvider
