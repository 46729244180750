import React from "react"
import { GatsbyProviders } from "@punks/gatsby"
import { MuiProviders } from "@punks/ui-mui"
import SanityPunksProviders from "./sanity"
import CoreProviders from "./core"

interface Props {
  children: any
}

export const PunksProviders = ({ children }: Props) => {
  return (
    <CoreProviders>
      <GatsbyProviders>
        <SanityPunksProviders>
          <MuiProviders>{children}</MuiProviders>
        </SanityPunksProviders>
      </GatsbyProviders>
    </CoreProviders>
  )
}
